import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import * as THREE from "three";
import { World } from "./world/World";

THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 0, 1);

export function App() {
  return (
    <main className="fullscreen">
      <Canvas
        shadows
        dpr={[2, 1]}
        frameloop="demand"
        camera={{ position: [-3, 0, 2], fov: 70 }}
      >
        <OrbitControls zoomSpeed={0.4} minDistance={1.5} maxDistance={20} />
        {/* <World /> */}
      </Canvas>
    </main>
  );
}
